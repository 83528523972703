import { Input, Table, Row, Col } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useColums from "./useColums";
import { XLSBoton } from "./XLSBoton";

////
export default function Tabla() {
  const { proveedoresColumns } = useColums();
  const { proveedores } = useSelector((state) => state.proveedoresReducer);

  const [listaFinal, setListaFinal] = useState([]);

  useEffect(() => {
    setListaFinal(proveedores);
  }, [proveedores]);

  ///

  const filter = ({ target }) => {
    if (target.value !== "") {
      var newList = proveedores.filter(
        (a) =>
          (a.nombreComercial &&
            a.nombreComercial
              .toLowerCase()
              .includes(target.value.toLowerCase())) ||
          (a.proveedorID &&
            a.proveedorID.toLowerCase().includes(target.value.toLowerCase())) ||
          (a.razonSocial &&
            a.razonSocial.toLowerCase().includes(target.value.toLowerCase())) ||
          (a.contactoNombre &&
            a.contactoNombre
              .toLowerCase()
              .includes(target.value.toLowerCase())) ||
          (a.contactoTelefono &&
            a.contactoTelefono
              .toLowerCase()
              .includes(target.value.toLowerCase()))
      );
      setListaFinal(newList);
    } else {
      setListaFinal(proveedores);
    }
  };

  ////

  const { Backoffice_DescargarProveedores } = useSelector(
    (state) => state.userReducer.user
  );
  return (
    <>
      <Row gutter={10}>
        <Col span={6}>
          <Input
            onChange={filter}
            placeholder="buscar"
            style={{ marginBottom: 10 }}
            size="small"
          />
        </Col>

        <Col span={12} style={{ paddingTop: 3 }}>
          {Backoffice_DescargarProveedores && <XLSBoton />}
        </Col>
      </Row>

      <Table
        rowKey="proveedorID"
        columns={proveedoresColumns}
        dataSource={listaFinal}
        size="small"
      />
    </>
  );
}
