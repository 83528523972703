import { Col, Row } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import LabelText from "../../../../components/input/LabelText";
import LabelSelect from "../../../../components/select/LabelSelect";
import { setConclusionCambioEspecial, setCostoCambioEspecial } from "../../../../context/actions/asistenciasActions";
import { BuscaProveedor } from "./BuscaProveedor";
import { ListaSucursales } from "./ListaSucursales";
import { ListadoProveedores } from "./ListadoProveedores";

export const Forma = () => {

  const dispatch = useDispatch();

  const estatusListado = [
    {texto: "Abierto", valor: 1},
    {texto: "Concluido", valor: 2},
    {texto: "Otorgado", valor: 3},
    {texto: "Cancelado con costo", valor: 4},
    {texto: "Cancelado sin costo", valor: 5},
  ]

  const onChangeCosto = (e) => {
      const {target: {value}} = e;
    dispatch(setCostoCambioEspecial(value))
  }

  const onChangeConclusion = (e) => {
    dispatch(setConclusionCambioEspecial(e))
  }

  const {costo, estatusFinalID} = useSelector((state) => state.asistenciasReducer.asistencia)

  return (
    <>
      <Row gutter={10}>
        <Col span={12}>
          <BuscaProveedor />
          <ListadoProveedores />
        </Col>
        <Col span={12}>
          <ListaSucursales />
        </Col>
      </Row>
{/* 
      <Row gutter={10}>
        <Col span={12}>
          <LabelText
            label="Costo"
            name="costo"
            onChange={onChangeCosto}
            defaultValue={costo}
          />
        </Col>
      </Row>

      <Row gutter={10}>
        <Col span={12}>
          <LabelSelect opciones={estatusListado} label={"Conclusion"} onChange={onChangeConclusion} defaultValue={estatusFinalID} />
        </Col>
      </Row> */}
    </>
  );
};
