import { Tabs } from "antd";
import React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { cleanOrdenPago } from "../../context/actions/ordenesPagoActions";
import { Busca } from "./busca/Busca";

import { Pagar } from "./pagar/Pagar";
import { ValidarFactura } from "./validarFactura/ValidarFactura";
import { OrdenesMes } from "./ordenesMes/OrdenesMes";
import { Autorizar } from "./autorizar/Autorizar";
import { Autorizar2 } from "./autorizar2/Autorizar2";
import { PorCargarPago } from "./porCargarPago/PorCargarPago";
import PagosMes from "./graficaPagosMes/PagosMes";
import { startGetProveedores } from "../../context/actions/proveedoresActions";


export const OrdenesPago = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(cleanOrdenPago());
    dispatch(startGetProveedores());
  }, []);


  const items = [
    {
      key: "1",
      label: `Buscar en Ordenes`,
      children: <Busca />,
    },
    {
      key: "2",
      label: `Órdenes del mes`,
      children: <OrdenesMes />,
    },
    {
      key: "3",
      label: `Validar factura`,
      children: <ValidarFactura />,
    },
    {
      key: "4",
      label: `Autorizar`,
      children: <Autorizar />,
    },
    {
      key: "5",
      label: `Autorizar2`,
      children: <Autorizar2 />,
    },
    {
      key: "6",
      label: `Pagar`,
      children: <Pagar />,
    },
    {
      key: "7",
      label: `Por Cargar Pago`,
      children: <PorCargarPago />,
    },
    {
      key: "8",
      label: `Pagos por mes`,
      children: <PagosMes />,
    },
  ];
  return <Tabs defaultActiveKey="1" items={items} />;
};
