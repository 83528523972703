import { Col, Row } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import LabelValor from "../../../../components/label/LabelValor";
import { CalificarEstrellas } from "./CalificarEstrellas";
import { EstatusProveedor } from "./EstatusProveedor";
import { entidadesFederativas, regimeArray } from "../../../../utils/provedorUtils";

export default function Info() {
  const history = useHistory();
  const {proveedorID} = useParams();
  const {
    noExterior,
    noInterior,
    nombreColonia,
    nombreMunicipio,
    razonSocial,
    nombreComercial,
    rfc,
    contactoNombre,
    contactoTelefono1,
    contactoTelefono2,
    contactoTelefono3,
    contactoEmail,
    ivaRetenido,
    isrRetenido,
    apellidoMaterno,
    cp,
    nombreVialidad,
    tipoPersona,
    nombre,
    apellidoPaterno,
    regimenFiscalId,
    entidadFederativa,
    iva,
    estrellas,
  } = useSelector((state) => state.proveedoresReducer.proveedor);

  /////
  const personaString = tipoPersona === 0 ? '' : tipoPersona === 1 ? 'Física' : tipoPersona === 2 ? 'Moral' : '';
  const regimen = regimeArray.filter(r => r.value === regimenFiscalId)
  const entidad = entidadesFederativas.filter(e => e.value === entidadFederativa)
  return (
    <>
      <EstatusProveedor />

      {tipoPersona === 1 &&
        <Row gutter={[10, 10]} style={{marginBottom: 20}}>

          <Col span={5}>
            <LabelValor label="Nombre" valor={nombre} />
          </Col>
          <Col span={5}>
            <LabelValor label="Apellido Paterno" valor={apellidoPaterno} />
          </Col>
          <Col span={5}>
            <LabelValor label="Apello Materno" valor={apellidoMaterno} />
          </Col>


        </Row>
      }

      {/*--------------------- */}
      <Row gutter={[10, 10]} style={{marginBottom: 10, marginTop: 10}}>
        <Col span={5}>
          <LabelValor label="Nombre comercial" valor={nombreComercial} />
        </Col>
        <Col span={5}>
          <LabelValor label="Razón social" valor={razonSocial} />
        </Col>
        <Col span={5}>
          <LabelValor label="RFC" valor={rfc} />
        </Col>
        <Col span={5}>
          <LabelValor label="Contacto" valor={contactoNombre} />
        </Col>
      </Row>
      <Row gutter={[10, 10]} style={{marginBottom: 10}}>
        <Col span={5}>
          <LabelValor label="Email" valor={contactoEmail} />
        </Col>

        <Col span={5}>
          <LabelValor label="Teléfono 1" valor={contactoTelefono1} />
        </Col>
        <Col span={5}>
          <LabelValor label="Teléfono 2" valor={contactoTelefono2} />
        </Col>
        <Col span={5}>
          <LabelValor label="Teléfono 3" valor={contactoTelefono3} />
        </Col>
      </Row>
      <Row gutter={[10, 10]} style={{marginBottom: 20}}>
        <Col span={5}>
          <LabelValor label="IVA" valor={iva} />
        </Col>
        <Col span={5}>
          <LabelValor label="IVA retenido" valor={ivaRetenido} />
        </Col>
        <Col span={5}>
          <LabelValor label="ISR Retenido" valor={isrRetenido} />
        </Col>
        <Col span={5}>
          <CalificarEstrellas estrellas={estrellas} />
        </Col>
      </Row>

      {/*Campos nuevos agregado by Luis M. Z*/}
      <Row gutter={[10, 10]} style={{marginBottom: 20}}>

        <Col span={5}>
          <LabelValor label="Codigo Postal" valor={cp} />
        </Col>
        <Col span={5}>
          <LabelValor label="Tipo de Persona" valor={personaString} />
        </Col>
        <Col span={5}>
          <LabelValor label="Regimen Fiscal" valor={regimen[0]?.label} />
        </Col>

        <Col span={5}>
          <LabelValor label="Entidad Federartiva" valor={entidad[0]?.label} />
        </Col>
        {/*Calle, número, apartamento. Código postal + Localidad + Departamento.*/}
        <Col span={15}>
          <LabelValor label="Dirección Completa" valor={`${nombreVialidad} ${noExterior}, int  ${noInterior}, ${cp},${nombreColonia}, ${nombreMunicipio}  ${entidad[0]?.label}`} />
        </Col>
      </Row>
    </>
  );
}
