import { URL_BASE_PROVEEDORES } from "../../constants/url";
import axios from "axios";
import { notification } from "./notificationActions";
import {
  composeErrorNotification,
  composeSuccessNotification,
} from "../../utils/composeNotification";
import { typesProveedorEstadosMunicipios } from "../types/typeProveedorMunicipio";



export const postMunicipioProveedor = (proveedorId, arrayMunicipios) => {
  console.log(arrayMunicipios);
  const url = `${URL_BASE_PROVEEDORES}/updateMunicipios/${proveedorId}`
  console.log({url,body: arrayMunicipios})

  const putFunction = async (tipoLp) => {
    await axios.put(
      url+`/${tipoLp}`,
      {
        ListaMunicipiosOrigen: arrayMunicipios[`origen${tipoLp}`],
        ListaMunicipiosDestino: arrayMunicipios[`destino${tipoLp}`],
      }
    );
  }

  return async (dispatch) => {
    try {
      await  Promise.all([putFunction(1), putFunction(2), putFunction(3)] )
      dispatch(
        notification(
          composeSuccessNotification(`Municipios  agregados correctamente`)
        )
      );
    } catch (e) {
      dispatch(
        notification(
          composeErrorNotification(
            "Error Post Municipio Origen",
            `${e.response.data.message}`
          )
        )
      );
    }
  };
};

export const getMunicipioProveedor = (proveedorId, tipo) => {
  return async (dispatch) => {
    if (tipo === 0 || undefined || proveedorId === undefined) return;
    try {
      const result = await axios.get(
        `${URL_BASE_PROVEEDORES}/GetMunicipiosProveedor/${proveedorId}`
      );
      console.log(result.data)

        dispatch(storeMuncipiosOrigenDestinoLPs(result.data));
        dispatch(storeMunicipioOrigen(result.data.municipioProveedorOrigen));
        dispatch(storeMunicipioDestino(result.data.municipioProveedorDestino));
    } catch (e) {
      dispatch(
        notification(
          composeErrorNotification(
            "Error Get Municipio Origen",
            `${e.response.data.message}`
          )
        )
      );
    }
  };
};

export const getAllEstados = () => {
  return async (dispatch) => {
    try {
      const result = await axios.get(`${URL_BASE_PROVEEDORES}/AllEstados`);
      dispatch(storeEstadoMunicipios(result.data));
    } catch (e) {
      dispatch(
        notification(
          composeErrorNotification(
            "Error Get Estados",
            `${e.response.data.message}`
          )
        )
      );
    }
  };
};

export const storeEstadoMunicipios = (data) => ({
  type: typesProveedorEstadosMunicipios.storeEstadosMunicipios,
  payload: data,
});

export const storeMunicipioOrigen = (data) => ({
  type: typesProveedorEstadosMunicipios.proveedorMunicipiosOrigen,
  payload: data,
});

export const storeMunicipioDestino = (data) => ({
  type: typesProveedorEstadosMunicipios.proveedorMunicipiosDestino,
  payload: data,
});

export const storeMuncipiosOrigenDestinoLPs = (data) => ({
  type: typesProveedorEstadosMunicipios.storeProvedoresMuncipiosLps,
  payload: data,
});


// Activa la autoasignación de un proveedor
export const setAutoasignacion = (proveedorId, boolean) => {
  return async (dispatch) => {
    try {
      await axios.post(
        `${URL_BASE_PROVEEDORES}/setAutoasignacion/${proveedorId}/${boolean}`
      );
      dispatch(
        notification(
          composeSuccessNotification(
            `Autoasignación ${boolean ? "activada" : "desactivada"}`
          )
        )
      );
    } catch (e) {
      dispatch(
        notification(
          composeErrorNotification(
            "Error Auto asignación",
            `${e.response.data.message}`
          )
        )
      );
    }
  };
};

