import { EyeOutlined } from "@ant-design/icons";
import { useHistory } from "react-router";

////
export default function useColums() {
  const history = useHistory();

  const handleClick = ({ proveedorID }) =>
    history.push(`/proveedores/detalle/${proveedorID}`);

  const proveedoresColumns = [
    {
      title: "Nombre",
      dataIndex: "nombreComercial",
    },
    {
      title: "Razon Social",
      dataIndex: "razonSocial",
    },
    {
      title: "Contacto",
      dataIndex: "contactoNombre",
    },
    {
      title: "Teléfono",
      dataIndex: "contactoTelefono1",
    },

    {
      title: "",
      width: 30,
      render: (text, record) => (
        <EyeOutlined
          onClick={() => handleClick(record)}
          style={{ color: "#ca2228" }}
          className="eyeIcon"
        />
      ),
    },
  ];

  return { proveedoresColumns };
}
