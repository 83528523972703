import { useSelector } from "react-redux";
import { formatCurrency } from "../../../utils/formatCurrency";

export const ControlesPorCargarPago = () => {
    const { ordenesPagoPorCargarPago } = useSelector((state) => state.ordenesPagoReducer);
  
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          marginTop: 10,
          marginBottom: 10,
        }}
      >
        <div
          style={{ display: "flex", justifyContent: "space-between", width: 380 }}
        >
          <p style={{ color: "#1890FF" }}>
            Total Ordenes:{" "}
            <span style={{ color: "black" }}>
              {ordenesPagoPorCargarPago.countOrdenes}
            </span>
          </p>
  
          <p style={{ color: "#1890FF" }}>
            Total Por Cargar Pago:{" "}
            <span style={{ color: "black" }}>
              {formatCurrency(ordenesPagoPorCargarPago.totalPorCargarPago)}
            </span>
          </p>
        </div>
      </div>
    );
  };