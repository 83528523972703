import { Generales } from "./generales/_Generales";
import CardPage from "../../../layout/CardPage";
import { Listados } from "./listados/Listados";

export const ReportesDia = () => {
  //
  return (
    <CardPage titulo="Reporte por Día">
      <Generales />
      <hr style={{ marginBottom: 20 }} />
      <Listados />
    </CardPage>
  );
};
