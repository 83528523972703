import { Col, Input, Row, Switch } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  updateServicioDato,
  updateServicioID,
} from "../../../../context/actions/campaniasActions";

export default function ServicioRow({ servicio }) {
  const [readOnly, setReadOnly] = useState(true);
  const dispatch = useDispatch();
  //

  let row = {};

  const { servicios } = useSelector(
    (state) => state.campaniasReducer.postServicios
  );

  servicios.map((s) => {
    if (s.servicioID === servicio.id) {
      row = { ...s };
    }
  });

  const handleChangeSwitch = (val) => {
    const body = { servicioID: servicio.id, accion: val, isActive: true };
    dispatch(updateServicioID(body));

    if (val) setReadOnly(false);
    else setReadOnly(true);
  };

  const handleChangeDestino = (val) => {
    const body = {
      servicioID: servicio.id,
      requiereDestino: val,
    };
    dispatch(updateServicioID(body));
  };

  const handleChangeInput = ({ target }) => {
    const body = { servicioID: servicio.id, [target.name]: target.value };

    dispatch(updateServicioDato(body));
  };

  //
  return (
    <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
      <Col span={2}>
        <Switch
          onChange={handleChangeSwitch}
          checkedChildren="Si"
          unCheckedChildren="No"
          checked={row.isActive}
        />
      </Col>
      <Col span={5}>
        <div>{servicio.nombre}</div>
      </Col>

      <Col span={3}>
        <Input
          readOnly={readOnly}
          name="eventos"
          onChange={handleChangeInput}
          placeholder="eventos"
          value={row.eventos}
        />
      </Col>
      <Col span={3}>
        <Input
          readOnly={readOnly}
          name="costoEvento"
          onChange={handleChangeInput}
          placeholder="costo x evento"
          value={row.costoEvento}
        />
      </Col>
      <Col span={3}>
        <Input
          readOnly={readOnly}
          name="km"
          onChange={handleChangeInput}
          placeholder="km"
          value={row.km}
        />
      </Col>
      <Col span={6}>
        <Input
          readOnly={readOnly}
          name="condicionesEspeciales"
          onChange={handleChangeInput}
          placeholder="condiciones especiales"
          value={row.condicionesEspeciales}
        />
      </Col>
    </Row>
  );
}
