import React from "react";
import CardPage from "../../../layout/CardPage";
import { Forma } from "./_Forma";
import { Response } from "./_Response";

//
export const PagosProveedor = () => {
  return (
    <CardPage titulo="Pagos a Proveedor" style={{ minHeight: 400 }}>
      <Forma />
      <Response />
    </CardPage>
  );
};
