import { URL_BASE_ORDENESPAGO, URL_BASE_REPORTES } from "../../constants/url";
import {
  composeErrorNotification,
  composeSuccessNotification,
} from "../../utils/composeNotification";
import { get, getFile, post, postGetFile } from "../../utils/fetch";
import { types } from "../types/types";
import { notification } from "./notificationActions";

////////////////////
export const startGetAsistenciasSinPago = () => {
  return async (dispatch) => {
    dispatch(setCargandoOrdenesSinPago(true));
    const url = `${URL_BASE_ORDENESPAGO}/GetAsistenciasSinPago`;
    const result = await get(url);
    if (result.code === 0) {
      dispatch(storeAsietenciasElegibles2(result.data));
      //  dispatch(storeAsistenciasSinPago(result.data));
      console.log(result.data);
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "Error de conexion - Get GetAsistenciasSinPago"
          )
        )
      );
    }
    dispatch(setCargandoOrdenesSinPago(false));
  };
};

const storeAsietenciasElegibles2 = (payload) => ({
  type: types.setAsistenciasElegibles2,
  payload,
});

/////////////

const setCargandoOrdenesSinPago = (status) => ({
  type: types.setCargandoOrdenesSinPago,
  payload: status,
});

/////////////

export const startGetOrdenesPago = () => {
  return async (dispatch, getState) => {
    const { mes, anio, proveedorID } =
      getState().ordenesPagoReducer.filtroOrdenesPago;
    let url;
    if (proveedorID)
      url = `${URL_BASE_ORDENESPAGO}/OrdenesPago/${mes}/${anio}/${proveedorID}`;
    else url = `${URL_BASE_ORDENESPAGO}/OrdenesPago/${mes}/${anio}`;

    const result = await get(url);
    console.log(url);
    if (result.code === 0) {
      dispatch(storeOrdenesPago(result.data));
    } else {
      dispatch(
        notification(
          composeErrorNotification("Error de conexion - Get OrdenesPago")
        )
      );
    }
  };
};

const storeOrdenesPago = (ordenesPago) => ({
  type: types.storeOrdenesPago,
  payload: ordenesPago,
});

export const setFiltroOrdenesPago = (elemento) => ({
  type: types.setFiltroOrdenesPago,
  payload: elemento,
});

/////////////////////////////////////////

export const startGetOrdenesPagoAutorizar = () => {
  return async (dispatch, getState) => {
    const url = `${URL_BASE_ORDENESPAGO}/GetOrdenesPagoByEstatus/4`;
    const result = await get(url);
    if (result.code === 0) {
      dispatch(storeOrdenesPagoAutorizar(result.data));
    } else {
      dispatch(
        notification(composeErrorNotification("GetOrdenesPagoByEstatus"))
      );
    }
  };
};

const storeOrdenesPagoAutorizar = (ordenesPago) => ({
  type: types.storeOrdenesPagoAutorizar,
  payload: ordenesPago,
});

export const startGetOrdenesValidarFactura = () => {
  return async (dispatch, getState) => {
    const url = `${URL_BASE_ORDENESPAGO}/GetOrdenesPagoByEstatus/3`;
    const result = await get(url);

    if (result.code === 0) {
      dispatch(storeOrdenesPagoValidarFactura(result.data));
    } else {
      dispatch(
        notification(composeErrorNotification("GetOrdenesPagoByEstatus"))
      );
    }
  };
};

const storeOrdenesPagoValidarFactura = (ordenesPago) => ({
  type: types.storeOrdenesPagoValidarFactura,
  payload: ordenesPago,
});

export const startGetOrdenesPagoPagar = () => {
  return async (dispatch, getState) => {
    const url = `${URL_BASE_ORDENESPAGO}/GetOrdenesPagoPorPagar`;
    const result = await get(url);
    if (result.code === 0) {
      dispatch(storeOrdenesPagoPagar(result.data));
    } else {
      dispatch(
        notification(composeErrorNotification("GetOrdenesPagoByEstatus/5"))
      );
    }
  };
};

const storeOrdenesPagoPagar = (ordenesPago) => ({
  type: types.storeOrdenesPagoPagar,
  payload: ordenesPago,
});

export const startGetOrdenesPagoPorCargarPago = () => {
  return async (dispatch, getState) => {
    const url = `${URL_BASE_ORDENESPAGO}/GetOrdenesPagoPorCargarPago`;
    const result = await get(url);
    if (result.code === 0) {
      dispatch(storeOrdenesPagoPorCargarPago(result.data));
      console.log(result.data);
    } else {
      dispatch(
        notification(composeErrorNotification("GetOrdenesPagoByEstatus/5"))
      );
    }
  };
};

const storeOrdenesPagoPorCargarPago = (ordenesPago) => ({
  type: types.storeOrdenesPagoPorCargarPago,
  payload: ordenesPago,
});

export const startGetOrdenesPagoByEstatus = (estatusID) => {
  return async (dispatch) => {
    dispatch(clearOrdenesPagoByEstatus());
    const url = `${URL_BASE_ORDENESPAGO}/GetOrdenesPagoByEstatus/${estatusID}`;
    const result = await get(url);
    console.log(url);
    if (result.code === 0) {
      dispatch(storeOrdenesPagoByEstatus(result.data));
    } else {
      dispatch(
        notification(composeErrorNotification("GetOrdenesPagoByEstatus"))
      );
    }
  };
};

const storeOrdenesPagoByEstatus = (ordenesPago) => ({
  type: types.storeOrdenesPagoByEstatus,
  payload: ordenesPago,
});

const clearOrdenesPagoByEstatus = () => ({
  type: types.clearOrdenesPagoByEstatus,
  payload: null,
});
///////////////////////////////

export const startDownloadTxtOrdenesPago = () => {
  return async (dispatch, getState) => {
    const { ordenesPagoTxtIDs } = getState().ordenesPagoReducer;
    const final = ordenesPagoTxtIDs.toString();

    const url = `${URL_BASE_ORDENESPAGO}/GenerarArchivoTXT/${final}`;

    const result = await getFile(url, "OrdenesPago.txt");
  };
};

export const startDownloadTxtExcelOrdenesPago = () => {
  return async (dispatch, getState) => {
    const { ordenesPagoTxtIDs } = getState().ordenesPagoReducer;
    const final = ordenesPagoTxtIDs.toString();

    console.log(final);

    const url = `${URL_BASE_ORDENESPAGO}/GenerarExcelTxt/${final}`;

    const result = await getFile(url, "OrdenesPagoExcel.xlsx");
  };
};

export const opTxtAddOrRemove = (ele) => {
  return async (dispatch, getState) => {
    let newOrdenes = [];
    const { ordenesPagoTxtIDs } = getState().ordenesPagoReducer;

    if (ele.action) {
      newOrdenes = [ ...ordenesPagoTxtIDs, ele.value ];
    } else {
      newOrdenes = ordenesPagoTxtIDs.filter((o) => o !== ele.value);
    }

    dispatch(setOrdenesPagoTxtIDs(newOrdenes));
  };
};

const setOrdenesPagoTxtIDs = (ordenes) => ({
  type: types.setOrdenesPagoTxtIDs,
  payload: ordenes,
});
/////////////////////////////////////////

export const startGetOrdenPago = (ordenPagoID) => {
  return async (dispatch) => {
    await dispatch(storeOrdenPagoItems([]));
    await dispatch(storeOrdenPago({}));
    const url = `${URL_BASE_ORDENESPAGO}/OrdenPago/${ordenPagoID}`;
    const result = await get(url);
    if (result.code === 0) {
      dispatch(storeOrdenPago(result.data));
      dispatch(startOrdenPagoItems(ordenPagoID));
      dispatch(
        startGetListaAsistenciasElegibles(result.data.proveedorID, ordenPagoID)
      );
    } else {
      dispatch(
        notification(
          composeErrorNotification("Error de conexion - Get OrdenesPago")
        )
      );
    }
  };
};

const storeOrdenPago = (ordenPago) => ({
  type: types.storeOrdenPago,
  payload: ordenPago,
});

const startOrdenPagoItems = (ordenPagoID) => {
  return async (dispatch) => {
    const url = `${URL_BASE_ORDENESPAGO}/OrdenPagoItems/${ordenPagoID}`;
    const result = await get(url);
    if (result.code === 0) {
      dispatch(storeOrdenPagoItems(result.data));
    } else {
      dispatch(
        notification(
          composeErrorNotification("Error de conexion - Get OrdenesPago")
        )
      );
    }
  };
};

const storeOrdenPagoItems = (items) => ({
  type: types.storeOrdenPagoItems,
  payload: items,
});

export const cleanOrdenPago = () => ({
  type: types.cleanOrdenPago,
  payload: null,
});
/////////////////////////////////////////

export const startPostCerateOrdenPago = (body) => {
  return async (dispatch, getState) => {
    const { usuarioID } = getState().userReducer.user;
    const url = `${URL_BASE_ORDENESPAGO}/CerateOrdenPago`;
    const result = await post(url, { ...body, usuarioID });

    if (result.code === 0) {
      dispatch(notification(composeSuccessNotification("Datos guadados")));
      return result.data;
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "Error de conexion - CerateOrdenPago",
            `${result.error}`
          )
        )
      );
    }
  };
};

////////////////////////////////////

export const startUpdateOrdenPagoRow = (body, ordenPagoID) => {
  return async (dispatch, getState) => {
    const { usuarioID } = getState().userReducer.user;
    const url = `${URL_BASE_ORDENESPAGO}/UpdateOrdenPagoRow`;
    console.log(body);
    const result = await post(url, { ...body, usuarioID });

    if (result.code === 0) {
      dispatch(notification(composeSuccessNotification(result.data)));
      dispatch(startGetOrdenPago(ordenPagoID));
    } else {
      dispatch(
        notification(
          composeErrorNotification("UpdateOrdenpagoRow", `${result.error}`)
        )
      );
    }
  };
};

export const startDeleteOrdenPagoRow = (ordenPagoItemID, ordenPagoID) => {
  return async (dispatch, getState) => {
    const { usuarioID } = getState().userReducer.user;
    const url = `${URL_BASE_ORDENESPAGO}/DeleteOrdenPagoRow/${ordenPagoItemID}/${usuarioID}`;
    const result = await get(url);

    if (result.code === 0) {
      dispatch(notification(composeSuccessNotification("Registro removido")));
      dispatch(startGetOrdenPago(ordenPagoID));
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "Error de conexion - DeleteOrdenpagoRow",
            `${result.error}`
          )
        )
      );
    }
  };
};

/////////////////////
export const startGetListaAsistenciasElegibles = (proveedorID, ordenPagoID) => {
  return async (dispatch) => {
    const url = `${URL_BASE_ORDENESPAGO}/GetListaAsistenciasElegibles/${proveedorID}/${ordenPagoID}`;
    const result = await get(url);

    if (result.code === 0) {
      dispatch(storeListaAsistenciasElegibles(result.data));
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "Error de conexion - GetListaAsistenciasElegibles",
            `${result.error}`
          )
        )
      );
    }
  };
};

const storeListaAsistenciasElegibles = (lista) => ({
  type: types.storeListaAsistenciasElegibles,
  payload: lista,
});

/////////////////////////////////
export const startAddOrdenPagoRow = (body) => {
  return async (dispatch, getState) => {
    const { usuarioID } = getState().userReducer.user;
    const url = `${URL_BASE_ORDENESPAGO}/AddOrdenPagoRow`;

    const result = await post(url, { ...body, usuarioID });

    if (result.code === 0) {
      dispatch(notification(composeSuccessNotification(result.data)));
      dispatch(startGetOrdenPago(body.ordenPagoID));
    } else {
      dispatch(
        notification(
          composeErrorNotification("AddOrdenPagoRow", `${result.error}`)
        )
      );
    }
  };
};

///////////////

export const startRechazarFactura = (body) => {
  return async (dispatch, getState) => {
    dispatch(setOrdenToEmailLoading(true));
    const { usuarioID } = getState().userReducer.user;
    const url = `${URL_BASE_ORDENESPAGO}/RechazarFactura`;

    const result = await post(url, { ...body, usuarioID });

    if (result.code === 0) {
      dispatch(notification(composeSuccessNotification(result.data)));
      dispatch(startGetOrdenPago(body.ordenPagoID));
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "Error de conexion - rechazar factura",
            `${result.error}`
          )
        )
      );
    }

    dispatch(setOrdenToEmailLoading(false));
  };
};

export const startOrdenToEmail = (body) => {
  return async (dispatch, getState) => {
    dispatch(setEnviandoProveedor(true));
    dispatch(setOrdenToEmailLoading(true));
    const { usuarioID } = getState().userReducer.user;
    const url = `${URL_BASE_ORDENESPAGO}/OrdenToEmail`;

    const result = await post(url, { ...body, usuarioID });

    if (result.code === 0) {
      dispatch(notification(composeSuccessNotification("Email enviado")));
      dispatch(startGetOrdenPago(body.ordenPagoID));
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "Error de conexion - SendEmail",
            `${result.error}`
          )
        )
      );
    }

    dispatch(setOrdenToEmailLoading(false));
    dispatch(setEnviandoProveedor(false));
  };
};

const setEnviandoProveedor = (state) => ({
  type: types.setEnviandoProveedor,
  payload: state,
});

const setOrdenToEmailLoading = (state) => ({
  type: types.setOrdenToEmailLoading,
  payload: state,
});

////////////////

export const startMarcarEstatus = (body) => {
  return async (dispatch) => {
    const url = `${URL_BASE_ORDENESPAGO}/MarcarEstatus`;
    const result = await post(url, body);

    if (result.code === 0) {
      dispatch(
        notification(composeSuccessNotification("Registro actualizado"))
      );
      dispatch(startGetOrdenPago(body.ordenPagoID));
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "Error de conexion - MarcarEstatus",
            `${result.error}`
          )
        )
      );
    }
  };
};

/////////////////////////////
export const startAddHistorialOrdenPago = (body) => {
  return async (dispatch) => {
    const url = `${URL_BASE_ORDENESPAGO}/AddHistorialOrdenPago`;
    const result = await post(url, body);

    if (result.code === 0) {
      dispatch(
        notification(composeSuccessNotification("Registro actualizado"))
      );
      dispatch(startGetOrdenPago(body.ordenPagoID));
    } else {
      dispatch(
        notification(
          composeErrorNotification("AddHistorialOrdenPago", `${result.error}`)
        )
      );
    }
  };
};

///////////

export const startDownloadDocumentoOP = (ordenPagoID, tipo) => {
  return async (dispatch, getState) => {
    let nombreFinal;
    let fileExt;
    const {
      complementoPagoUri,
      facturaUri,
      facturaXMLUri,
      verificacionFacturaUri,
      pagoUri,
    } = getState().ordenesPagoReducer.ordenPago;
    const url = `${URL_BASE_ORDENESPAGO}/DownloadDocumentoOP/${ordenPagoID}/${tipo}`;

    if (tipo === 0) {
      fileExt = facturaUri.split(".").pop();
      nombreFinal = `Factura_PDF_${ordenPagoID}.${fileExt}`;
    }

    if (tipo === 1) {
      fileExt = pagoUri.split(".").pop();
      nombreFinal = `Factura_PDF_${ordenPagoID}.${fileExt}`;
    }

    if (tipo == 2) {
      fileExt = complementoPagoUri.split(".").pop();
      nombreFinal = `ComplementoPago_${ordenPagoID}.${fileExt}`;
    }

    if (tipo == 3) {
      fileExt = facturaXMLUri.split(".").pop();
      nombreFinal = `Factura_XML_${ordenPagoID}.${fileExt}`;
    }

    if (tipo == 4) {
      fileExt = verificacionFacturaUri.split(".").pop();
      nombreFinal = `ValidacionFactura_${ordenPagoID}.${fileExt}`;
    }

    if (tipo == 5) {
      nombreFinal = `OP_${ordenPagoID}.pdf`;
    }

    console.log(nombreFinal);
    const result = await getFile(url, nombreFinal);

    if (result.code === -1) {
      dispatch(
        notification(
          composeErrorNotification(
            "Error de conexion - Download documento OP",
            `${result.data}`
          )
        )
      );
    }
  };
};

////////////////////////////////////////////////////

export const startAutorizaOP = (ordenPagoID) => {
  return async (dispatch, getState) => {
    const { usuarioID } = getState().userReducer.user;
    const url = `${URL_BASE_ORDENESPAGO}/AutorizaOP`;
    const result = await post(url, { ordenPagoID, usuarioID });

    if (result.code === 0) {
      dispatch(notification(composeSuccessNotification(result.data)));
      dispatch(startGetOrdenPago(ordenPagoID));
    } else {
      dispatch(
        notification(
          composeErrorNotification("startAutorizaOP", `${result.error}`)
        )
      );
    }
  };
};

export const startAutorizaOP2 = (ordenPagoID) => {
  return async (dispatch, getState) => {
    const { usuarioID } = getState().userReducer.user;
    const url = `${URL_BASE_ORDENESPAGO}/AutorizaOP2`;
    const result = await post(url, { ordenPagoID, usuarioID });

    console.log(url);
    console.log(result);
    if (result.code === 0) {
      dispatch(notification(composeSuccessNotification(result.data)));
      dispatch(startGetOrdenPago(ordenPagoID));
    } else {
      dispatch(
        notification(
          composeErrorNotification("startAutorizaOP", `${result.error}`)
        )
      );
    }
  };
};

export const startRechazarOP = (body) => {
  return async (dispatch, getState) => {
    const { usuarioID } = getState().userReducer.user;
    const url = `${URL_BASE_ORDENESPAGO}/RechazarOP`;
    const result = await post(url, { ...body, usuarioID });

    if (result.code === 0) {
      dispatch(notification(composeSuccessNotification(result.data)));
      dispatch(startGetOrdenPago(body.ordenPagoID));
    } else {
      dispatch(
        notification(composeErrorNotification("RechazarOP", `${result.error}`))
      );
    }
  };
};
/////////////////////////

export const startGetOrdenPagoItemsXLS = (ordenPagoID) => {
  return async (dispatch) => {
    const url = `${URL_BASE_ORDENESPAGO}/GetOrdenPagoItemsXLS/${ordenPagoID}`;
    const result = await getFile(url, "OrdenPago_" + ordenPagoID + ".xls");

    if (result.code === 0) {
      //
    } else {
      dispatch(
        notification(
          composeErrorNotification("GetOrdenPagoItemsXLS", `${result.error}`)
        )
      );
    }
  };
};

////////////////////////////////////////////////

export const startGetProveedoresMes = () => {
  return async (dispatch, getState) => {
    const { mes, anio } = getState().ordenesPagoReducer.filtroProveedoresMes;
    const url = `${URL_BASE_REPORTES}/GetProveedoresMes/${mes}/${anio}`;
    const result = await get(url);
    if (result.code === 0) {
      dispatch(storeProveedoresMes(result.data));
    } else {
      dispatch(notification(composeErrorNotification("GetProveedoresMes")));
    }
  };
};

export const storeProveedoresMes = (elemento) => ({
  type: types.storeProveedoresMes,
  payload: elemento,
});

export const setFiltroProveedoresMes = (elemento) => ({
  type: types.setFiltroProveedoresMes,
  payload: elemento,
});

/////

export const startGetClientesMes = () => {
  return async (dispatch, getState) => {
    const { mes, anio } = getState().ordenesPagoReducer.filtroClientesMes;
    const url = `${URL_BASE_REPORTES}/GetClientesMes/${mes}/${anio}`;
    const result = await get(url);
    if (result.code === 0) {
      dispatch(storeClientesMes(result.data));
    } else {
      dispatch(notification(composeErrorNotification("GetClientesMes")));
    }
  };
};

export const storeClientesMes = (elemento) => ({
  type: types.storeClientesMes,
  payload: elemento,
});

export const setFiltroClientesMes = (elemento) => ({
  type: types.setFiltroClientesMes,
  payload: elemento,
});

/////
export const startGetOrdenPagoXLSByEstatus = (estatusOrdenPagoID) => {
  return async (dispatch) => {
    const url = `${URL_BASE_ORDENESPAGO}/GetOrdenPagoXLSByEstatus/${estatusOrdenPagoID}`;
    const result = await getFile(url, "OrdenesPago.xls");

    if (result.code === 0) {
      //
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "GetOrdenPagoXLSByEstatus",
            `${result.error}`
          )
        )
      );
    }
  };
};

export const filterOrdenPagoPagar = (value) => {
  return async (dispatch, getState) => {
    const { ordenesPagoPagar } = getState().ordenesPagoReducer;
    const filtered = ordenesPagoPagar.ordenes.filter((o) => o.cliente === value);
    dispatch(setOrdenPagoPagarFiltered(filtered));
  }
}

export const setOrdenPagoPagarFiltered = (arrayOrdenes) => {
  return {
    type: types.setOrdenPagarFiltered,
    payload: arrayOrdenes ?? []
  }
}