import React from "react";
import { useSelector } from "react-redux";
import { AsistenciasItem } from "./_AsistenciasItem";

export const Asistencias = () => {
  const {
    asistenciasDia: { regs: asistencias },
  } = useSelector((state) => state.reportesReducer);
  console.log(asistencias);
  //
  return (
    <div>
      <div
        className="tituloCabeceraRound"
        style={{ fontSize: 16, fontWeight: 600 }}
      >
        Asistencias
      </div>
      {asistencias && (
        <div style={{ height: 400, overflowY: "auto", overflowX: "hidden" }}>
          {asistencias.map((asistencia, i) => (
            <AsistenciasItem key={i} asistencia={asistencia} />
          ))}
        </div>
      )}
    </div>
  );
};
