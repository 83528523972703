import React, { useEffect, useState } from "react";
import { Col, Row, DatePicker, Button } from "antd";
import { ProveedoresSelect } from "../../../components/select/ProveedoresSelect";
import { useDispatch } from "react-redux";
import { startGetProveedores } from "../../../context/actions/proveedoresActions";
import { startGetPagosProveedorByFecha } from "../../../context/actions/reportesActions";
const { RangePicker } = DatePicker;

export const Forma = () => {
  const dispatch = useDispatch();
  const [body, setBody] = useState({});

  useEffect(() => {
    dispatch(startGetProveedores());
  }, []);
  //
  const handleChange = (value) => {
    setBody({
      ...body,
      proveedorID: value,
    });
  };

  const isDiabled = () => {
    if (!body.proveedorID || !body.fechas) return true;
    else if (body.fechas[0] === "" || body.fechas[1] === "") return true;
    return false;
  };
  //
  const handleChangeDate = (date, dateString) => {
    setBody({
      ...body,
      fechas: [dateString[0], dateString[1]],
    });
  };

  //
  const handleGenerar = () => dispatch(startGetPagosProveedorByFecha(body));

  console.log(body);
  //
  return (
    <Row gutter={[10, 10]}>
      <Col xs={24} md={12} lg={8}>
        <ProveedoresSelect onChange={handleChange} />
      </Col>
      <Col xs={24} sm={12} md={8} lg={6}>
        <RangePicker style={{ width: "100%" }} onChange={handleChangeDate} />
      </Col>
      <Col xs={24} sm={12} md={8} lg={6}>
        <Button
          disabled={isDiabled()}
          style={{ width: "100%" }}
          onClick={handleGenerar}
          type="primary"
        >
          Generar
        </Button>
      </Col>
    </Row>
  );
};
