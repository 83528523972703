import React from "react";
import { useSelector } from "react-redux";
import { ServiciosItem } from "./_ServiciosItem";

//
export const Servicios = () => {
  const [open, setOpen] = React.useState(true);

  const {
    asistenciasDia: { servicios },
  } = useSelector((state) => state.reportesReducer);

  return (
    <div>
      <div
        onClick={() => setOpen(!open)}
        className="tituloCabeceraRound"
        style={{ fontSize: 16, fontWeight: 600, cursor: "pointer" }}
      >
        Servicios
      </div>
      <div style={{ display: open ? "" : "none" }}>
        {servicios && (
          <div style={{ paddingRight: 4,height: 240, overflowY: "auto", overflowX: "hidden" }}>
            {servicios.map((servicio, i) => (
              <ServiciosItem key={i} servicio={servicio} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
