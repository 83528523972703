import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { opTxtAddOrRemove } from "../../../context/actions/ordenesPagoActions";
import { Checkbox, Col, Row, Space, Tooltip } from "antd";
import { formatCurrency } from "../../../utils/formatCurrency";
import { CheckCircleTwoTone, EyeOutlined } from "@ant-design/icons";
import { CargaPagoBoton } from "./cargaPagoBoton/CargaPagoBoton";
import { useState } from "react";

export const OrdenesItemPorCargarPago = ({ o }) => {

    const [pagoCargado, setPagoCargado] = useState(o.fechaCargaPago);
    const dispatch = useDispatch()
    const history = useHistory();

    console.log(pagoCargado);
  
    //
    const change = (target) => {
      const body = {
        value: target.name, action: target.checked
      }
      dispatch(opTxtAddOrRemove(body))
    }
  
    //
    return (
      !pagoCargado &&
      <Row gutter={10} className="rowItemElemento">
        <Col span={1}>
          <Checkbox name={o.ordenPagoID} onChange={({ target }) => change(target)} />
        </Col>
        <Col span={1}>
          {o.ordenPagoID}
        </Col>
        <Col span={2}>
         {o.fechaAutorizacionPago}
        </Col>
        <Col span={2}>
         {o.fechaAutorizacionPago2}
        </Col>
        <Col span={5}>
          {o.razonSocial}
        </Col>
        <Col span={3}>{o.banco}</Col>
        <Col span={2}>{o.cliente}</Col>
        <Col span={2} style={{textAlign: "center"}}>
          {o.servicios}
        </Col>
        <Col span={2} style={{ textAlign: "center" }}>
          {formatCurrency(o.subTotal)}
        </Col>
        <Col span={2} style={{ textAlign: "center" }}>
          {formatCurrency(o.total)}
        </Col>
        <Col span={2} style={{ textAlign: "center" }}>
          <Space>
            <Tooltip title="Abrir" placement="bottom">
              <EyeOutlined
                onClick={() =>
                  history.push(`/ordenesPago/detalle/${o.ordenPagoID}`)
                }
                className="eyeIcon"
                style={{ color: "#ca2228", fontSize: 16 }}
              />
            </Tooltip>
          </Space>
  
          <Space>
              <CargaPagoBoton ordenPagoID={o.ordenPagoID} setPagoCargado={setPagoCargado} />
            </Space> 
        </Col>
      </Row>
    );
  };