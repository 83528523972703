import React from "react";
import { useSelector } from "react-redux";
import { formatCurrency } from "../../../utils/formatCurrency";
import { Col, Row } from "antd";
import { formatCurrencyDecimals } from "../../../utils/formatCurrencyDecimals";

export const Response = () => {
  const { nombreComercial, cantidad, monto, inicial } = useSelector(
    (state) => state.reportesReducer.pagosProveedorByFecha
  );

  if (inicial)
    return (
      <div className="cajaSeleccione">
        Seleccione un proveedor y rango de fecha
      </div>
    );

  if (!nombreComercial) return <div className="cajaSeleccione">Sin Pagos</div>;
  //
  return (
    <div className="cajaSeleccione">
      <Row style={{ marginBottom: 10 }}>
        <Col xs={24} md={8} lg={6} xl={4} style={{ fontWeight: 600 }}>
          Proveedor:
        </Col>
        <Col xs={24} md={16}>
          {nombreComercial}
        </Col>
      </Row>
      <Row style={{ marginBottom: 10 }}>
        <Col style={{ fontWeight: 600 }} xs={24} md={8} lg={6} xl={4}>
          Órdenes:
        </Col>
        <Col xs={24} md={16}>
          {cantidad}
        </Col>
      </Row>
      <Row style={{ marginBottom: 10 }}>
        <Col style={{ fontWeight: 600 }} xs={24} md={8} lg={6} xl={4}>
          Monto:
        </Col>
        <Col xs={24} md={16}>
          {formatCurrencyDecimals(monto, 2)}
        </Col>
      </Row>
    </div>
  );
};
